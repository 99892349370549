<template>
    <Row class="text-center">
      <i-col span="24">
        <Button
          type="success"
          class="m-l-8"
          size="small"
          @click="handleCreateAgreement"
          >开始创建确认单</Button>
      </i-col>
    </Row>
</template>

<script>
export default {
  methods: {
    handleCreateAgreement () {
      this.$emit('on-create')
    }
  }
}
</script>
